@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.AlertBanner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 50%;
  font-family: Verizon-NHG-eDS;
  font-weight: bolder;
  border-radius: 0.6rem;
  pointer-events: auto;

  &.border {
    border: 1px solid black;
  }

  @include mediumMax {
    width: 100%;
  }

  .guidanceWrapper {
    position: relative;
    padding: 15px 30px;
    border-radius: 0.5rem 0.5rem 0 0;

    .guidance {
      font-family: Verizon-NHG-eDS;
      font-weight: bold;
      font-size: 32px;
      color: black;
      margin: unset;

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }
  }

  .messageWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 130px;
    padding: 20px 30px;

    @media (max-width: 576px) {
      padding: 15px 30px 30px 30px;
      min-height: 170px;
    }

    .message {
      font-size: 24px;
      font-weight: bold;
      margin: 0 0 10px 0;
    }
  }

  .link {
    text-decoration: none;
  }

  .UpdateContainer {
    display: flex;
    position: relative;
    gap: 10px;
    align-items: center;

    .update {
      margin: unset;

      &:hover {
        text-decoration: underline;
      }
    }

    .Caret {

      @keyframes shake {
        50%,
        80%,
        100% {
          transform: translateX(0px);
        }
        70% {
          transform: translateX(-5px);
        }
        90% {
          transform: translateX(-5px);
        }
      }

      position: relative;
      top: 2px;
      animation: shake 2s infinite ease-in-out;
    }
  }
}