$white: #ffffff;
$black: #000000;
$gray-1: #edeff2;
$gray-2: #d4d7db;
$gray-3: #6a737d;
$gray-4: #24292e;
$light: #f6f6f6;

$vzRed: #d52b1e;
$dont: #d52b1e;
$do: #00ac3e;
$blueIcon: #0088ce;
$ruleRed: #dc3420;
$monarchRed: #F50A23;
$monarchStone: #F3EDE0;
$monarchYellow: #F5FF1E;
