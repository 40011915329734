@import "../../styles/breakpoints";

.Autocomplete {
  position: absolute;
  margin: 0 25px;
  top: 60px;
  width: calc(100% - 50px);
  background-color: #f6f6f6;
  @include medium {
    left: 42px;
    top: 80px;
    padding: 0;
    max-width: 450px;
    margin: 0;
  }
}