@import '../../styles/breakpoints';

.Pagination {
  margin: 0 auto;
  max-width: 75px;
  font-weight: bold;
  font-family: BrandFont-Display;
  padding: 10px 0 30px;

  @include medium {
    padding: 0 0 0 20px;
    margin: 0;
    max-width: none;
  }
  
  button {
    border: none;
    background-color: white;
  }

  .Next {
    top: 2px;
    position: relative;
    padding: 0 20px 0 0;
  }

  .Prev {
    top: 2px;
    position: relative;
    padding: 0 0 0 20px;
  }

  .Active {
    display: inline-block;
    padding-bottom: 2px;
    margin-bottom: -2px;
    border-bottom: black solid thin;
    font-weight: bold;
  }
}
