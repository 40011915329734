@import "../../styles/breakpoints";

.Breadcrumbs {
  padding: 8px 0 7px;

  @include mediumMax {
    padding: 10px;
  }

  a {
    color: black;
    display: inline;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    display: inline;
  }
}

.BreadcrumbsResults {
  padding: 40px 20px 0;
  margin-bottom: 1rem;
}