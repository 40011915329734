@use '../../styles/colors';
@import '../../styles/breakpoints';

.SearchResultCard {
  border: 1px solid #d8dada;
  width: 100%;
  max-width: 400px;
  height: 200px;
  margin: 10px auto;
  padding: 20px;
  font-family: Verizon-NHG-eDS;
  color: black;
  text-decoration: none;


  @include medium {
    height: 370px;
    overflow-y: hidden;
    max-width: 260px;
    margin: 20px 10px;
  }
}

.SearchResultCard_Subtitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  padding: 0 0 5px 0;
  @include medium {
     padding: 0 0 10px 0;
  }
}

.SearchResultCard_Title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  padding: 0 0 15px 0;
  @include medium {
     padding: 0 0 20px 0;
  }
}

.SearchResultCard_Body {
  font-size: 16px;
  line-height: 20px;
  display: inline;
  p {
    margin: 0;
  }
}

.Ellipses {
  display: inline;
  @include medium {
    display: none;
  }
}


