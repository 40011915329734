@use '../../../styles/colors';
@import '../../../styles/breakpoints';

.SearchField {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -55px;

  @media (min-width: $breakpoint-small-end) {
    padding: 0 2.5em;
  }

  &.subPage {
    margin: -1rem 0 2rem 0;
    padding: unset;
  }

  @include mediumMax {
    margin-top: -35px;
  }

  .formWrapper {
    position: relative;
    padding: 0 20px;

    &.subPage {
      padding: unset;
    }

    input {
      border: 0;
      font-family: Verizon-NHG-eDS;
      font-weight: bold;
      color: black;
      font-size: 16px;

      &:focus {
        outline: #D8DADA;
      }
    }

    .Home {
      position: relative;

      .searchBar {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        border: 1px solid black;
        border-radius: 50px;
        padding: 3.5px 20px;

        @media (min-width: 576px) {
          padding: 14px 40px;
          gap: 32px
        }
      }

      svg {
        position: relative;
        max-width: 18px;
        @include large {
          max-width: unset;
          width: 34px;
          height: 34px;
        }
      }

      input {
        display: inline-block;
        background-color: transparent;
        font-size: 18px;
        line-height: 22px;
        width: 100%;
        height: 35px;
        @include large {
          font-size: 2.25em;
          height: auto;
        }

        svg {
          position: relative;
          width: 34px;
          height: 34px;
          @include large {
          }
        }
      }

      input::placeholder {
        color: black;
      }

    }

    .SearchResults {
      padding: 20px 20px 40px;

      svg {
        position: relative;
        left: 20px;
        top: 40px;
        max-width: 15px;
      }

      input {
        width: 100%;
        background-color: #f6f6f6;
        font-size: 20px;
        line-height: 24px;
        padding: 15px 0 15px 42px;
      }
    }

    button {
      display: none;
    }


    .Detail {

      input {
        height: 40px;
        width: 240px;
        position: relative;
        background-color: white;
        border-radius: 30px;
        border: 1px solid black;
        padding-left: 40px;
      }

      input::placeholder {
        color: black;
      }

      svg {
        fill: #747474;
        position: relative;
        top: 35px;
        max-width: 16px;
        z-index: 100;
        left: 1rem;
        @include medium {
          left: 1.1rem;
        }
      }
    }
  }

  .guidanceWrapper {
    position: relative;
    visibility: hidden;
    padding: 0 20px 66px 20px;
    animation-name: slideUp;
    animation-duration: 4.5s;
    animation-fill-mode: forwards;
    animation-delay: 3.75s;

    &.arbp {
      animation-delay: 5.5s;
    }

    @media (max-width: 576px) {
      padding: 0 20px 30px 20px;
    }

    @include mediumMax {
      animation: none;
      visibility: visible;
    }

    @keyframes slideUp {
      0%,
      50% {
        transform: translateY(100%);
        opacity: 0;
        visibility: visible;
      }

      60%,
      100% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }

    .guidance {
      position: relative;
      font-family: Verizon-NHG-eDS;
      font-weight: bold;
      font-size: 24px;
      color: colors.$vzRed;
    }

    .alertWrapper {
      position: relative;
      gap: 20px;
      justify-content: space-between;

      &.desktop {
        display: none;
        @include small {
          display: flex;
        }
      }

      &.mobile {
        display: flex;
        @include small {
          display: none;
        }
      }

      @include mediumMax {
        flex-direction: column;
      }
    }
  }
}
