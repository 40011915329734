.AutocompleteResults {
  button {
   display: block !important;
   padding: 10px 37px 10px;
   width: 100%;
   border: 0;
   text-align: left;
   z-index: 1000;
   position: relative;
   margin: 0;
   span {
      word-wrap: break-word;
      line-height: 24px;
      color: grey;
   }
    &:last-child {
       padding: 10px 37px 25px;
    }
  }
}