.wrapper {
  position: relative;
  background-color: #FFFF;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  position: relative;
  display: flex;
  justify-content: start;
  align-content: center;
  flex-wrap: wrap;
  height: auto;
  background-color: #FFFF;
  height: 40px
}

.dot {
  height: 17px;
  width: 17px;
  background-color: #f6f6f6;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4rem;
}

.main {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.leftNav {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  width: 280px;
  background-color: #f6f6f6;
}

.navItem {
  position: relative;
  background-color: #FFF;
  height: 17px;
  padding: 0.25rem;
  border-radius: 2.5%;
  &:nth-child(even) {
    width: 180px;
  }
  &:nth-child(odd) {
    width: 240px;
  }
}

.container {
  position: relative;
  display: grid;
  width: 100%;
  height: 820px;
  grid-template-columns: auto / repeat(1, 1fr);
  gap: 1rem;
}

.content {
  position: relative;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 2.5%;

  &:nth-child(even) {
    animation: fadein 2s infinite;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
  &:nth-child(odd) {
    animation: fadeOut 2s infinite;
    animation-delay: 1s;
    animation-fill-mode: forwards;  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}