.svg {
  position: absolute;
  font-size: 6rem;
  width: 6rem;
  height: 6rem;
  top: calc(50% - 4rem);
  left: calc(50% - 4rem);
  margin: auto;
  color: inherit;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}