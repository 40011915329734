@import '../../styles/breakpoints';

.SearchResults {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}

.SearchResults_Endcap {
  background-color: #f6f6f6;
  max-width: 400px;
  width: 100%;
  height: 240px;
  margin: 20px auto;
  padding: 60px 10px 20px 10px;
  font-family: Verizon-NHG-eDS;
  color: black;
  text-align: center;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
   @include medium {
      max-width: 260px;
      height: 370px;
      margin: 20px 10px;
      padding: 120px 10px 20px 10px;
  }
}

.SearchResults_Endcap span{
  font-weight: 700;
}

.SearchResults_Endcap a{
  font-size: 16px;
  line-height: 20px;
  border-radius: 20px;
  border: 1px solid black;
  display: block;
  text-decoration: none;
  color: white;
  background-color: black;
  max-width: 150px;
  margin: 30px auto;
  padding: 10px 10px;
}