@import "../styles/breakpoints";
@import "../styles/colors";

.headerWrapper {
  position: sticky;
  top: 0;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 1000;
  background-color: white;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  overflow: auto;

  @include mediumMin {
    display: flex;
  }
}

.container {

  @include mediumMin {
    padding-left: 300px;  
    margin: 0 auto;
    overflow-y: hidden;
    max-width: 1440px;
  }
  width: 100%;

}

.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sidebarButton {
  background-color: transparent;
  color: black;
  position: sticky;
  z-index: 100;
  padding: 10px;
  width: 48px;

  svg {
    margin: 0 10px 0 0;
    position: relative;
    top: 3px;
  }

  @include mediumMin {
    display: none;
  }
}

.homeContainer, .resultsContainer {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  overflow: clip;
}

.signInContainer {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  margin-top: 5rem;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.List {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.heroImage {
  display: block;
  width: 100vw;
  height: 50vw;

  @include mediumMin {
    width: 100%;
    height: auto;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
  width: 90%;

  > * {
    align-self: stretch; // IE11
  }

  > :last-child {
    margin-top: 40px;
  }

  img {
    margin-top: 20px;
  }
}

a, a:hover, a:focus, a:active {
  color: inherit;
}

.signInBlockContainer {
  display: flex;
  flex-direction: column;
  @include mediumMin {
    flex-direction: row;
  }
}

.pageHeader {
  margin-top: 0.425rem;
  margin-bottom: 3.425rem;
}

.pageWrapper {
  position: relative;
  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;
  @include mediumMin {
    padding: 0 1.25rem;
    max-width: 1440px;
  }
}

.pageTitle {
  margin-bottom: 5rem;
  margin-top: 0;
  font-size: 3.25rem;
  font-family: Verizon-NHG-eDS;
  font-weight: bold;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.signInFooter {
  font-size: 13px;
  color: $black;
  margin-top: 5rem;
}

.inquiry {
  margin-top: 5rem;
}

.inquiryHeading {
  color: $black;
  font-family: Verizon-NHG-eDS;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.inquiryText {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  margin-top: 8px;
  font-family: Verizon-NHG-eDS;
}

.inquiryLink {
  margin-top: 12px;
  height: 44px;
}

.inquiryLinkText {
  font-size: 0.9rem;
  height: 44px;
  line-height: 44px;
  font-family: Verizon-NHG-eDS;
  font-weight: bold;
  color: $black;
  text-decoration: none;
}

.inquiryLinkCarat {
  margin-left: 0.5em;
  font-size: .875em;
  line-height: 1.2em;
  position: relative;
  top: .1em;
}

.layoutBlock {
  flex-direction: column;
}

.layoutGutter > .layoutCol + .layoutCol {
  margin-left: 2%;
}

.layout {
  display: flex;
  
  &+& {
    margin-top: 20px;
  }
}

.layoutCol {
  width: 100%;
  height: 100%;
  
  & > * {
    max-width: 100%;
  }
}

.fetchingLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  font-size: 4rem;
  background-color: rgba(255, 255, 255, 0.7);
  
  LoadingIndicator {
    position: absolute;
    font-size: 4rem;
    width: 4rem;
    height: 4rem;
    top: calc(50% - 2rem);
    left: calc(50% - 2rem);
    margin: auto;
  }
}