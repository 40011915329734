@import "../../styles/colors";

.Footer {
  font-size: 13px;
  padding: 0 1em;
  color: $black;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: auto;
  font-family: Verizon-NHG-eDS;

  @media (max-width: 576px) {
    padding: unset;
  }
}

.footerDivider {
  position: relative;
  height: 66px;
  border-bottom: 1px solid #D8DADA;

  &.subpage {
    height: 22px;

    @media (max-width: 576px) {
      height: 40px;
    }
  }
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: left;
  min-height: 122px;

  &.subpage {
    min-height: 64px;

    @media (max-width: 576px) {
      padding: 0 1em
    }
  }

  .Link {
    margin: 0 10px;
  }
}